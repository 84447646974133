export default () => {
  return [
    {
      title: 'UPS',
      value: 'UPS',
      extraHtml: '<span class="ups-icon"></span>',
    },
    {
      title: 'FedEx',
      value: 'FedEx',
      extraHtml: '<span class="fedex-icon"></span>',
    },
  ]
}
