<template>
  <div class="pagination">
    <ul class="pagination__list">
      <li class="pagination__item">
        <a
          class="pagination__btn pagination__btn--nav"
          @click.prevent="prevPage"
          href="#"
        >
          <i class="fas fa-chevron-left"></i>
        </a>
      </li>
      <li v-for="page in links" :key="page" class="pagination__item">
        <a
          v-if="page !== 'dots'"
          class="pagination__btn"
          :class="{ 'pagination__btn--active': currentPage === page }"
          @click.prevent="$emit('changePage', page)"
          href="#"
        >
          {{ page }}
        </a>
        <a v-else class="pagination__btn">
          ...
        </a>
      </li>
      <li class="pagination__item">
        <a
          class="pagination__btn pagination__btn--nav"
          @click.prevent="nextPage"
          href="#"
        >
          <i class="fas fa-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    protectedPages: {
      type: Number,
      default: 5,
    },
  },
  methods: {
    prevPage() {
      if (this.currentPage === 1) return

      this.$emit('changePage', this.currentPage - 1)
    },
    nextPage() {
      if (this.currentPage === this.totalPages) return

      this.$emit('changePage', this.currentPage + 1)
    },
  },
  computed: {
    links() {
      const sourceLinks = Array.from(
        { length: this.totalPages },
        (v, k) => k + 1
      )
      if (this.currentPage === 1 || this.currentPage === this.totalPages) {
        if (this.totalPages > this.protectedPages * 2) {
          const result = sourceLinks.slice(0, this.protectedPages)
          return result.concat('dots', sourceLinks.slice(-this.protectedPages))
        } else {
          return sourceLinks
        }
      } else {
        if (this.currentPage + this.protectedPages < this.totalPages) {
          const result = sourceLinks.slice(
            this.currentPage - 1,
            this.currentPage + this.protectedPages - 1
          )
          return result.concat('dots', sourceLinks.slice(-this.protectedPages))
        } else {
          if (this.currentPage > this.protectedPages) {
            return sourceLinks.slice(
              this.currentPage - this.protectedPages,
              this.totalPages
            )
          } else {
            return sourceLinks
          }
        }
      }
    },
  },
}
</script>
