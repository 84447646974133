<template>
  <div class="app-preloader">
    <div class="app-preloader__logo">
      <AppLogo />
    </div>
    <p class="app-preloader__text">
      Loading...
    </p>
  </div>
</template>

<script>
import AppLogo from '@/core/components/content/AppLogo'

export default {
  name: 'AppPreloader',
  components: {
    AppLogo,
  },
}
</script>
