<template>
  <div class="input-toggle" :class="{ 'input-toggle--disabled': disabled }">
    <label v-if="label" class="input-toggle__label">{{ label }}</label>
    <div class="input-toggle__wrapper">
      <div
        class="input-toggle__btn"
        :class="{ 'input-toggle__btn--active': selected === 'mm' }"
        @click="toggle('mm')"
      >
        mm
      </div>
      <div
        class="input-toggle__btn"
        :class="{ 'input-toggle__btn--active': selected === 'in' }"
        @click="toggle('in')"
      >
        in
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputUnitsToggle',
  props: {
    selected: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  methods: {
    toggle(units) {
      this.$emit('toggle', units)
    },
  },
}
</script>
