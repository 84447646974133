<template>
  <div class="dropdown">
    <div class="dropdown__wrapper">
      <slot name="button" />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
}
</script>
