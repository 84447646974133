<template>
  <button :disabled="disabled" class="button-base" @click="$emit('click')">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonBase',
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
