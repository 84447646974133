<template>
  <div class="switch">
    <label v-if="label" class="switch__label switch__label--grey">
      {{ label }}
    </label>
    <div class="switch__items">
      <div
        class="switch__item"
        v-for="item in items"
        :key="item.value"
        :class="{ 'switch__item--selected': item.value === selected }"
        @click="selectItem(item)"
      >
        <span class="switch__item-icon" />
        <span class="switch__item-text">{{ item.title }}</span>
        <span v-if="item.extraHtml" v-html="item.extraHtml"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputSwitch',
  props: {
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
      default: '',
    },
  },
  methods: {
    selectItem(item) {
      this.$emit('select', item)
    },
  },
}
</script>
