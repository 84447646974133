<template>
  <AppModal
    name="talk-to-engineer"
    :loading="loading"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
    @close="$vfm.hide('talk-to-engineer')"
  >
    <div class="u-margin-bottom-small">
      <h1 class="page-title">
        Talk to an Engineer
      </h1>
    </div>
    <div>
      <template v-if="isRequestSent">
        <div class="u-text-center">
          <div class="u-margin-bottom-xsmall">
            <p class="paragraph paragraph--grey">
              Thank you! Your request has been submitted.
            </p>
          </div>
          <p class="paragraph paragraph--grey">
            We'll be contacting you shortly.
          </p>
        </div>
      </template>
      <template v-else>
        <div class="talk-engineer-body">
          <div
            class="talk-engineer-body__item talk-engineer-body__item--bordered"
          >
            <div class="u-margin-bottom-small">
              <h3 class="page-title page-title--small">Contact Us</h3>
            </div>
            <p class="paragraph paragraph--grey u-margin-bottom-xsmall">
              Email:
              <a href="mailto:expert@fathommfg.com" class="link link--black">
                expert@fathommfg.com
              </a>
            </p>
            <div class="u-margin-bottom-xxsmall">
              <p class="paragraph paragraph--grey">Phone: 877-328-4668</p>
            </div>
          </div>
          <div class="talk-engineer-body__item">
            <div class="u-margin-bottom-small">
              <h3 class="page-title page-title--small">
                Would you like us to contact you?
              </h3>
            </div>
            <div class="u-margin-bottom-small">
              <p class="paragraph paragraph--grey">
                Enter your information below and a Fathom expert will contact
                you shortly (typically 1-2 hours during business hours).
              </p>
            </div>
            <div class="talk-engineer-body__form">
              <div class="talk-engineer-body__form-group">
                <InputText
                  label="First Name"
                  placeholder="First Name"
                  :errors="validations.errors.firstName"
                  :show-errors="submitted"
                  v-model="firstName"
                />
                <InputText
                  label="Last Name"
                  placeholder="Last Name"
                  :errors="validations.errors.lastName"
                  v-model="lastName"
                  :show-errors="submitted"
                />
              </div>
              <div class="talk-engineer-body__form-group">
                <InputText
                  label="Company Name"
                  placeholder="Company Name"
                  :errors="validations.errors.companyName"
                  :show-errors="submitted"
                  v-model="companyName"
                />
                <InputText
                  label="Company Email"
                  placeholder="Company Email"
                  :errors="validations.errors.companyEmail"
                  :show-errors="submitted"
                  v-model="companyEmail"
                />
              </div>
              <div class="talk-engineer-body__form-group">
                <InputText
                  :mask="phoneMask"
                  label="Phone"
                  placeholder="Phone"
                  :errors="validations.errors.phone"
                  :show-errors="submitted"
                  v-model="phone"
                />
              </div>
              <div class="talk-engineer-body__form-group">
                <InputText
                  :textarea="true"
                  label="Notes"
                  placeholder="Notes"
                  :errors="validations.errors.notes"
                  :show-errors="submitted"
                  v-model="notes"
                />
              </div>
              <div class="talk-engineer-body__form-button">
                <ButtonPrimary
                  :disabled="loading"
                  width="25rem"
                  @click="submit"
                >
                  Submit
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </AppModal>
</template>

<script>
import { required, email, phone } from '@/core/plugins/validator/validators'
import { PHONE_MASK } from '@/core/utils/masks'
import { getErrorMessage } from '@/core/helpers/error'
import { createEngineerRequest } from '@/api/supportApi'

import AppModal from '@/core/components/modals/AppModal'
import InputText from '@/core/components/inputs/InputText'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'

const validators = {
  firstName: [required()],
  lastName: [required()],
  companyEmail: [required(), email()],
  companyName: [required()],
  phone: [required(), phone()],
  notes: [required()],
}

export default {
  name: 'TalkToEngineerModal',
  components: {
    ButtonPrimary,
    AppModal,
    InputText,
  },
  data() {
    return {
      isRequestSent: false,
      loading: false,
      companyName: '',
      companyEmail: '',
      firstName: '',
      lastName: '',
      phone: '',
      notes: '',
      errorMessage: '',
      phoneMask: PHONE_MASK,
      submitted: false,
    }
  },
  computed: {
    validations() {
      return this.$validator.validate(
        {
          firstName: this.firstName,
          lastName: this.lastName,
          companyEmail: this.companyEmail,
          companyName: this.companyName,
          phone: this.phone,
          notes: this.notes,
        },
        validators
      )
    },
  },
  methods: {
    onBeforeOpen() {},
    onClosed() {
      this.isRequestSent = false
      this.loading = false
      this.submitted = false
      this.companyName = ''
      this.companyEmail = ''
      this.firstName = ''
      this.lastName = ''
      this.phone = ''
      this.notes = ''
      this.errorMessage = ''
    },
    async submit() {
      this.submitted = true

      if (!this.validations.isValid || this.loading) return

      this.errorMessage = ''

      try {
        this.loading = true

        await createEngineerRequest({
          firstName: this.firstName,
          lastName: this.lastName,
          company: this.companyName,
          companyEmail: this.companyEmail,
          phone: this.phone,
          page: this.$route.fullPath,
          notes: this.notes,
        })

        this.isRequestSent = true
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
