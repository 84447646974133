<template>
  <div class="app-loader">
    <AppSpinner />
    <div v-if="text" class="app-loader__text">
      {{ text }}
    </div>
  </div>
</template>

<script>
import AppSpinner from '@/core/components/content/AppSpinner'

export default {
  name: 'AppLoader',
  components: {
    AppSpinner,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>
