<template>
  <div class="app-person-dropdown" v-click-outside="hide">
    <div class="app-person-dropdown__btn" @click="toggle">
      <div class="app-person-dropdown__icon">
        {{ initials }}
      </div>
    </div>
    <div class="account-menu__dropdown" v-if="opened">
      <ul class="account-menu__list">
        <li class="account-menu__item">
          <router-link class="account-menu__link" :to="{ name: 'dashboard' }">
            Personal Dashboard
          </router-link>
        </li>
        <li class="account-menu__item">
          <a
            class="account-menu__link"
            @click.prevent="openAccountSettings"
            href="#"
          >
            Account Settings
          </a>
        </li>
        <li class="account-menu__item">
          <a
            class="account-menu__link account-menu__link--danger"
            @click.prevent="logout"
            href="#"
          >
            Logout
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from '@/core/directives/click-outside'
import { mapGetters } from 'vuex'

export default {
  name: 'AppPersonDropdown',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      opened: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    initials() {
      const { firstName, lastName } = this.user

      const result = []

      if (firstName) {
        result.push(firstName.charAt(0))
      }

      if (lastName) {
        result.push(lastName.charAt(0))
      }

      return result.join('')
    },
  },
  methods: {
    toggle() {
      this.opened = !this.opened
    },
    hide() {
      this.opened = false
    },
    openAccountSettings() {
      this.hide()
      this.$vfm.show('account-settings')
    },
    async logout() {
      await this.$store.dispatch('auth/logout')
      await this.$router.replace({ path: '/' })
      this.hide()
    },
  },
}
</script>
