<template>
  <footer class="app-footer">
    <div class="container">
      <div class="app-footer__wrapper">
        <div class="app-footer__content">
          <h3 class="app-footer__title">
            We are Secure. Confidential. Certified.
          </h3>
          <div class="app-footer__divider" />
          <p class="app-footer__text">
            ISO 9001:2015 + ISO 9001:2015 Design Certified + AS9100:2016
            Certified + ITAR Registered + NIST 800-171 Compliant + ISO 13485
            Certified
          </p>
        </div>
        <div class="app-footer__certifications">
          <div
            class="app-footer__certificate app-footer__certificate--iso9001"
          />
          <div
            class="app-footer__certificate app-footer__certificate--iso9001-design-certified"
          />
          <div
            class="app-footer__certificate app-footer__certificate--iso13485"
          />
          <div
            class="app-footer__certificate app-footer__certificate--as9100"
          />
          <div class="app-footer__certificate app-footer__certificate--nist" />
          <div class="app-footer__certificate app-footer__certificate--itar" />
          <div
            class="app-footer__certificate app-footer__certificate--inc5000"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
