<template>
  <a v-if="href && download" class="btn-default" :class="classes" :href="href">
    <slot />
  </a>
  <button
    v-else
    class="btn-default"
    :class="classes"
    :disabled="disabled"
    :style="style"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonDefault',
  inheritAttrs: false,
  props: {
    outline: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    download: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      return {
        'btn-default--outline': this.outline,
        'btn-default--small': this.small,
        'btn-default--danger': this.theme === 'danger',
        'btn-default--round': this.round,
      }
    },
    style() {
      return {
        width: this.width,
      }
    },
  },
}
</script>
