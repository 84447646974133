<template>
  <AppModal
    name="non-accepted-files"
    :loading="loading"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
    @close="$vfm.hide('non-accepted-files')"
  >
    <div class="u-margin-bottom-xsmall u-flex-center">
      <h1>Upload error.</h1>
    </div>
    <div class="u-margin-bottom-small u-flex-center">
      <h2>The following file(s) were not accepted:</h2>
    </div>
    <div class="u-margin-bottom-small">
      <InputText
        :textarea="true"
        :disabled="true"
        height="10rem"
        :model-value="nonAcceptedFiles"
      />
    </div>
    <template v-if="nonAcceptedExtFiles.length">
      <div class="u-margin-bottom-small u-flex-center">
        <h2>Please review the accepted file formats and try again.</h2>
      </div>
      <div class="u-margin-bottom-small u-flex-center">
        <div class="technology-selector u-flex-center">
          <div
            class="technology-selector__static-item"
            v-for="technology in technologies"
            :key="technology.id"
          >
            <div
              class="technology-selector__heading"
              v-html="technology.name"
            />
            <div
              class="technology-selector__content technology-selector__content-center"
              v-html="technology.acceptedFormats"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-if="nonAcceptedAsciiFiles.length">
      <div class="u-margin-bottom-small u-flex-center">
        <h2>
          File character format isn't accepted. Please rename the file to an
          ASCII formatting.
        </h2>
      </div>
    </template>

    <div class="u-flex-center">
      <ButtonPrimary width="15rem" @click="$vfm.hide('non-accepted-files')"
        >OK</ButtonPrimary
      >
      <div>
        <p class="paragraph--grey">
          Need help? <a class="u-underline">Talk to an expert</a>
        </p>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { acceptedFormats } from '@/core/helpers/accepted-formats'
import AppModal from '@/core/components/modals/AppModal'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'
import InputText from '../inputs/InputText'

export default {
  name: 'NonAcceptedFilesModal',
  components: {
    InputText,
    AppModal,
    ButtonPrimary,
  },
  data() {
    return {
      loading: false,
      acceptedFormats: '',
      nonAcceptedExtFiles: '',
      nonAcceptedAsciiFiles: '',
      technologies: [
        {
          name: '3D Printing',
          acceptedFormats: acceptedFormats({ is3D: true })
            .join(', ')
            .toUpperCase(),
        },
        {
          name: 'CNC, Urethane Casting & Injection Molding',
          acceptedFormats: acceptedFormats({})
            .join(', ')
            .toUpperCase(),
        },
      ],
    }
  },
  computed: {
    nonAcceptedFiles() {
      const result = []
      const merged = [
        ...this.nonAcceptedExtFiles,
        ...this.nonAcceptedAsciiFiles,
      ]
      merged.forEach(item => {
        if (!result.includes(item)) result.push(item)
      })
      return result.join('\r\n')
    },
  },
  methods: {
    onBeforeOpen(e) {
      if ('acceptedFormats' in e.ref.params.value) {
        this.acceptedFormats = e.ref.params.value.acceptedFormats.join(',')
      }
      if ('nonAcceptedExtFiles' in e.ref.params.value) {
        this.nonAcceptedExtFiles = e.ref.params.value.nonAcceptedExtFiles.map(
          item => item.original.name
        )
      }
      if ('nonAcceptedAsciiFiles' in e.ref.params.value) {
        this.nonAcceptedAsciiFiles = e.ref.params.value.nonAcceptedAsciiFiles.map(
          item => item.original.name
        )
      }
    },
    onClosed() {},
  },
}
</script>
