<template>
  <div class="promo-box" :class="{ 'promo-box--mobile': mobile }">
    <div class="promo-box__heading">
      <h4 class="promo-box__menu-title">Menu</h4>
      <button class="promo-box__close" @click="$emit('close')" />
    </div>
    <slot name="menu" />
    <div class="promo-box__main" :class="{ 'promo-box__main--mobile': mobile }">
      <div class="promo-box__logo" />
      <h3 v-if="!mobile" class="promo-box__title">
        Over 100K quotes provided <br />
        to top US companies
      </h3>
      <div v-if="!mobile" class="promo-box__content">
        <h1 class="promo-box__main-title">Get an Instant Quote</h1>
        <h3 class="promo-box__main-description">
          Simply upload your file(s), and select a technology process to get
          started.
        </h3>
        <p class="promo-box__description">
          Configure your part with desired materials and finishing on the next
          page.
        </p>
      </div>
      <div v-if="!mobile" class="promo-box__content">
        <h1 class="promo-box__main-title">Need help?</h1>
        <ul class="promo-box__list">
          <li class="promo-box__list-item">
            <a class="promo-box__link" @click="showTalkEngineerModal" href="#">
              <span> > </span> Talk to an Engineer
            </a>
          </li>
          <li class="promo-box__list-item">
            <a
              class="promo-box__link"
              target="_blank"
              href="https://fathommfg.com/manual-quote"
            >
              <span> > </span> Get a Manual Quote
            </a>
          </li>
          <li class="promo-box__list-item">
            <a
              class="promo-box__link"
              target="_blank"
              href="https://fathommfg.com/itar-request"
            >
              <span> > </span> ITAR Quote
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="promo-box__footer">
      <nav class="promo-box__nav">
        <ul class="promo-box__nav-list">
          <li class="promo-box__nav-item" v-click-outside="hideDropdown">
            <Dropdown>
              <a
                class="link link--primary link--no-decoration"
                href="#"
                @click.prevent="toggleDropdown"
              >
                Helpful Resources <i class="fas fa-chevron-down"></i>
              </a>
              <div
                v-if="dropdownOpened"
                class="dropdown__list dropdown__list--top dropdown__list--wide"
              >
                <div class="dropdown__item">
                  <a
                    class="link link--no-decoration"
                    target="_blank"
                    href="https://fathommfg.com/overview"
                    @click="hideDropdown"
                    >3D Printing Technologies</a
                  >
                </div>
                <div class="dropdown__item">
                  <a
                    class="link link--no-decoration"
                    target="_blank"
                    href="https://fathommfg.com/3d-printing-materials"
                    @click="hideDropdown"
                    >3D Printing Materials</a
                  >
                </div>
                <div class="dropdown__item">
                  <a
                    class="link link--no-decoration"
                    target="_blank"
                    href="https://fathommfg.com/smartquote-resources"
                    @click="hideDropdown"
                    >Knowledge Center</a
                  >
                </div>
                <div class="dropdown__item">
                  <a
                    class="link link--no-decoration"
                    target="_blank"
                    href="https://fathommfg.com/videos"
                    @click="hideDropdown"
                    >Featured Videos</a
                  >
                </div>
              </div>
            </Dropdown>
          </li>
          <li class="promo-box__nav-item">
            <a
              class="promo-box__nav-link"
              target="_blank"
              href="https://fathommfg.com/smartquote-faq"
              >FAQs</a
            >
          </li>
          <li class="promo-box__nav-item">
            <a
              class="promo-box__nav-link"
              href="#"
              @click="showTalkEngineerModal"
              >Contact Us</a
            >
          </li>
        </ul>
      </nav>
      <div class="promo-box__copyright">
        <Copyright />
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/core/components/controls/Dropdown'
import ClickOutside from '@/core/directives/click-outside'
import Copyright from '@/core/components/content/Copyright'

export default {
  name: 'PromoBox',
  components: {
    Dropdown,
    Copyright,
  },
  directives: {
    ClickOutside,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropdownOpened: false,
    }
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpened = !this.dropdownOpened
    },
    hideDropdown() {
      this.dropdownOpened = false
    },
    showTalkEngineerModal() {
      this.$vfm.show('talk-to-engineer')
    },
  },
}
</script>
