<template>
  <div
    class="app-logo"
    :class="{ 'app-logo--clickable': clickable }"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'AppLogo',
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
