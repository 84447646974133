<template>
  <span>&copy; {{ currentYear }} FATHOM, All Rights Reserved</span>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Copyright',
  data: () => ({
    currentYear: moment().year(),
  }),
}
</script>
