<template>
  <div class="page-heading" :class="{ 'page-heading--inline': inline }">
    <template v-if="inline">
      <h1 class="page-heading__title">
        <span v-if="subtitle">{{ subtitle }}</span>
        <span>{{ title }}</span>
      </h1>
    </template>
    <template v-else>
      <h3 v-if="subtitle" class="page-heading__subtitle">
        {{ subtitle }}
      </h3>
      <h1 class="page-heading__title">{{ title }}</h1>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PageHeading',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
