<template>
  <div>
    <h2 class="u-margin-bottom-xsmall">A Quick Question</h2>
    <p
      class="paragraph paragraph--large paragraph--grey u-margin-bottom-xsmall"
    >
      Before you upload your files, is your project subject to export control
      regulations including, but not limited to, the International Traffic in
      Arms Regulations (ITAR), 22 C.F.R. 120-130? <br />(Not sure what that
      means?

      <a
        class="link--gray"
        target="_blank"
        href="https://fathommfg.com/itar-manufacturing"
      >
        Click here to learn more.)
      </a>
    </p>
    <p
      class="paragraph--large paragraph--grey paragraph--strong u-margin-bottom-small"
    >
      To submit an ITAR or regulated project,
      <a
        class="link--gray"
        target="_blank"
        href="https://fathommfg.com/itar-request"
      >
        click here.
      </a>
    </p>
    <div class="u-flex-left u-flex-left--space u-margin-top-xsmall">
      <InputRadioGroup
        :selected="itarOption"
        :items="options"
        :disabled="disabled"
        :width="width"
        @select="onChangeItarOption"
        class="u-margin-bottom-small"
      >
        I can self-attest that files I am uploading are not ITAR
      </InputRadioGroup>
      <div>
        <div
          v-if="errorMessage"
          class="u-margin-bottom-xsmall u-margin-top-xsmall"
        >
          <p class="paragraph paragraph--red">{{ errorMessage }}</p>
        </div>
      </div>
    </div>

    <div v-if="showItarMessage" class="u-margin-bottom-small">
      <span class="paragraph--large paragraph--grey">
        <span class="paragraph--red paragraph--strong"
          >*Do not submit ITAR or regulated projects here.
          <a
            class="link--red link--normal"
            target="_blank"
            href="https://fathommfg.com/itar-request"
          >
            To submit an ITAR or regulated project, click here.
          </a>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import InputRadioGroup from '../inputs/InputRadioGroup.vue'

export default {
  name: 'ItarSelect',
  components: {
    InputRadioGroup,
  },
  props: {
    itarOption: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '50rem',
    },
  },
  data() {
    return {
      options: [
        {
          title: `<p class="paragraph paragraph--grey paragraph--large">No, this project <span class="u-underline">does not</span> contain data subject to Export Control regulations</p>`,
          value: 'no',
        },
        {
          title: `<p class="paragraph paragraph--grey paragraph--large">Yes, this project contains data subject to Export Control regulations</p>`,
          value: 'yes',
        },
      ],
      confirm: false,
      errorMessage: '',
    }
  },
  computed: {
    showItarMessage() {
      return this.itarOption === 'yes'
    },
  },
  methods: {
    onChangeItarOption(value) {
      this.confirm = false
      this.$emit('change', value)
    },
  },
}
</script>
