export const getErrorMessage = err => {
  if (err && err.response && err.response.data) {
    if (err.response.data.details || err.response.data.detail) {
      return err.response.data.details || err.response.data.detail
    }
    const errors = []
    const keys = Object.keys(err.response.data)
    keys.forEach(key => {
      if (err.response.data[key] instanceof Array) {
        errors.push(err.response.data[key][0])
      }
    })
    return errors.join(', ')
  }
  if (err) return err.message
}

export const handleErrors = (err, store) => {
  const errorObj = {
    status: 500,
    message: 'Something went wrong. Try again later.',
  }

  if (err.response && err.response.status) {
    errorObj.status = err.response.status
    errorObj.message = getErrorMessage(err)
  }

  if (errorObj.status !== 401) {
    store.commit('SET_ERROR', errorObj)
  }
}
