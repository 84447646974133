<template>
  <div class="btn-iconic" @click="$emit('click')" :class="classes">
    <div v-if="$slots.info" class="btn-iconic__info">
      <slot name="info" />
    </div>
    <button class="btn-iconic__btn">
      <i :class="iconClasses" />
    </button>
    <span class="btn-iconic__text" v-if="text">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ButtonIconic',
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      default: 'edit',
    },
    theme: {
      type: String,
      default: 'primary',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      return {
        'btn-iconic--primary': this.themePrimary,
        'btn-iconic--danger': this.themeDanger,
        'btn-iconic--grey': this.themeGrey,
      }
    },
    iconClasses() {
      return {
        'far fa-edit': this.iconEdit,
        'fas fa-trash': this.iconTrash,
        'fas fa-ellipsis-h': this.iconDots,
      }
    },
    iconDots() {
      return this.icon === 'dots'
    },
    iconEdit() {
      return this.icon === 'edit'
    },
    iconTrash() {
      return this.icon === 'trash'
    },
    themePrimary() {
      return this.theme === 'primary'
    },
    themeGrey() {
      return this.theme === 'grey'
    },
    themeDanger() {
      return this.theme === 'danger'
    },
  },
}
</script>
