<template>
  <VueFinalModal
    v-model="showModal"
    :name="name"
    classes="app-modal-container"
    :content-class="contentClass"
    :click-to-close="clickToClose"
    :esc-to-close="escToClose"
    @beforeOpen="$emit('beforeOpen', $event)"
    @opened="$emit('opened')"
    @closed="$emit('closed')"
  >
    <transition name="fade" appear mode="out-in">
      <div v-if="loading" class="app-modal__loading">
        <AppSpinner />
      </div>
    </transition>

    <span v-if="closable" class="app-modal__close" @click="$emit('close')" />

    <div
      v-if="showModal"
      class="app-modal__body"
      :class="{
        'app-modal__body--base': base,
        'app-modal__body--simple': simple,
      }"
    >
      <slot />
    </div>
  </VueFinalModal>
</template>

<script>
import AppSpinner from '@/core/components/content/AppSpinner'

export default {
  name: 'AppModal',
  components: {
    AppSpinner,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    clickToClose: {
      type: Boolean,
      default: false,
    },
    escToClose: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    base: {
      type: Boolean,
      default: false,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'm',
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    contentClass() {
      return ['app-modal-content', `app-modal-content--${this.size}`]
    },
  },
}
</script>
