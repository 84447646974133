export const validate = (data, validators) => {
  const errors = {}
  let errorsCount = 0
  Object.keys(validators).forEach(key => {
    errors[key] = []
    if (key in data) {
      validators[key].forEach(item => {
        const { validator, message } = item
        if (!validator(data[key], data)) {
          errors[key].push(message)
          errorsCount++
        }
      })
    }
  })
  return { isValid: errorsCount === 0, errors }
}

export default {
  install: app => {
    app.config.globalProperties.$validator = { validate }
  },
}
