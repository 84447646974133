<template>
  <button class="button-upload" @click="$emit('click')" :disabled="disabled">
    <i class="fas fa-cloud-upload-alt" />
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'ButtonUpload',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: 'Upload More Files',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
