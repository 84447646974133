<template>
  <button class="button-rounded-icon" @click="$emit('click')">
    <i v-if="icon === 'configure'" class="fas fa-cog" />
  </button>
</template>

<script>
export default {
  name: 'ButtonRoundedIcon',
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>
