<template>
  <button
    :id="id"
    class="button-submit"
    :style="styles"
    @click="$emit('click')"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonSubmit',
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
  },
  computed: {
    styles() {
      return {
        width: this.width,
      }
    },
  },
}
</script>
