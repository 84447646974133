<template>
  <div
    class="input-quantity"
    :class="{
      'input-quantity--disabled': disabled,
      'input-quantity--inline': inline,
    }"
  >
    <label v-if="label" class="input-quantity__label">{{ label }}</label>
    <div class="input-quantity__wrapper">
      <button @click="decrementValue" class="input-quantity__btn">
        <i class="fas fa-minus" />
      </button>
      <input
        type="text"
        class="input-quantity__input"
        :style="styles"
        :disabled="disabled"
        :value="modelValue"
        v-maska="quantityMask"
        @blur="setValue($event.target.value)"
        @keyup.enter="setValue($event.target.value)"
      />
      <button @click="incrementValue" class="input-quantity__btn">
        <i class="fas fa-plus" />
      </button>
    </div>
  </div>
</template>

<script>
import { maska } from 'maska'

export default {
  name: 'InputQuantity',
  directives: {
    maska,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: 1,
    },
    maxValue: {
      type: Number,
      default: 10000,
    },
    minValue: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    quantityMask() {
      return '######'
    },
    styles() {
      return {
        height: this.height,
      }
    },
  },
  methods: {
    setValue(value) {
      if (isNaN(value)) return
      if (value < this.minValue) value = +this.minValue
      if (value > this.maxValue) value = +this.maxValue
      if (value !== this.modelValue)
        this.$emit('update:modelValue', parseInt(value))
    },
    incrementValue() {
      if (this.modelValue + 1 > this.maxValue) return

      if (this.disabled) return

      this.$emit('update:modelValue', this.modelValue + 1)
    },
    decrementValue() {
      if (this.modelValue - 1 < this.minValue) return

      if (this.disabled) return

      this.$emit('update:modelValue', this.modelValue - 1)
    },
  },
}
</script>
