<template>
  <header
    class="app-header"
    :class="{ 'app-header--quote': quote, 'app-header--main': isMainPage }"
  >
    <div class="container">
      <div class="app-header__wrapper">
        <div class="app-header__burger-menu" @click="toggleMobileMenu" />
        <AppLogo
          v-if="showLogo"
          :clickable="true"
          @click="$router.push({ name: 'main' })"
        />
        <nav v-if="isMainPage" class="app-header__nav app-header__nav--main">
          <ul class="app-header__nav-list">
            <li class="app-header__nav-item">
              <a
                class="app-header__nav-link"
                target="_blank"
                href="https://fathommfg.com/manual-quote"
              >
                Manual quote
              </a>
            </li>
            <li class="app-header__nav-item">
              <a
                class="app-header__nav-link"
                target="_blank"
                href="https://fathommfg.com/itar-request"
              >
                Itar
              </a>
            </li>
            <li v-if="isLoggedIn" class="app-header__nav-item">
              <router-link
                class="app-header__nav-link"
                :to="{ name: 'dashboard' }"
              >
                Dashboard
              </router-link>
            </li>
          </ul>
        </nav>
        <div class="app-header__aside">
          <nav v-if="!isMainPage" class="app-header__nav">
            <ul class="app-header__nav-list">
              <li class="app-header__nav-item">
                <a
                  class="app-header__nav-link"
                  target="_blank"
                  href="https://fathommfg.com/manual-quote"
                >
                  Manual quote
                </a>
              </li>
              <li class="app-header__nav-item">
                <a
                  class="app-header__nav-link"
                  target="_blank"
                  href="https://fathommfg.com/itar-request"
                >
                  Itar
                </a>
              </li>
              <li v-if="isLoggedIn" class="app-header__nav-item">
                <router-link
                  class="app-header__nav-link"
                  :to="{ name: 'dashboard' }"
                >
                  Dashboard
                </router-link>
              </li>
            </ul>
          </nav>
          <a class="app-header__engineer-link" @click="showTalkEngineerModal">
            <span class="app-header__engineer-icon" />
            Talk To An Engineer
          </a>
          <div v-if="isLoggedIn" class="app-header__account">
            <AppPersonDropdown />
          </div>
          <button v-else class="app-header__btn" @click="showAuthModal">
            Sign In
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

import AppLogo from '@/core/components/content/AppLogo'
import AppPersonDropdown from '@/core/components/content/AppPersonDropdown'

export default {
  name: 'AppHeader',
  components: {
    AppLogo,
    AppPersonDropdown,
  },
  props: {
    quote: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mobileMenuVisible: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'user']),
    isMainPage() {
      return this.$route && this.$route.name === 'main'
    },
  },
  methods: {
    showAuthModal() {
      this.$vfm.show('auth')
    },
    showTalkEngineerModal() {
      this.$vfm.show('talk-to-engineer')
    },
    toggleMobileMenu() {
      this.mobileMenuVisible = !this.mobileMenuVisible
    },
  },
}
</script>
