<template>
  <div class="error-view">
    <div class="error-view__body">
      <div class="container">
        <div class="error-view__grid">
          <div class="error-view__status">
            {{ error.status }}
          </div>
          <div class="error-view__content">
            <h1 class="error-view__title">
              Oops!
            </h1>
            <template v-if="isNotFound">
              <p class="error-view__description">
                {{ type }} not found. Please return to the homepage.
              </p>
              <ButtonPrimary width="25rem" @click="onSubmit">
                Go back home
              </ButtonPrimary>
            </template>
            <template v-else>
              <p class="error-view__description">
                {{ error.message }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'

export default {
  name: 'ErrorOverlay',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  components: {
    ButtonPrimary,
  },
  computed: {
    type() {
      return this.$route.name && this.$route.name.startsWith('quote')
        ? 'Quote'
        : 'Page'
    },
    isNotFound() {
      return this.error.status === 404
    },
  },
  methods: {
    async onSubmit() {
      await this.$router.replace({ name: 'main' })

      this.$store.commit('SET_ERROR', null)
    },
  },
}
</script>
