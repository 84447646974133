<template>
  <div class="input-checkbox" :class="{ 'input-checkbox--disabled': disabled }">
    <input
      :id="id"
      type="checkbox"
      :checked="modelValue"
      :disabled="disabled"
      autocomplete="off"
      class="input-checkbox__input"
      @change="onValueChange"
    />
    <label
      class="input-checkbox__label"
      :class="{ 'input-checkbox__label--no-content': !$slots['default'] }"
      :for="id"
    >
      <i class="fas fa-check input-checkbox__icon" />
      <slot />
    </label>
  </div>
</template>

<script>
import { v4 } from 'uuid'

export default {
  name: 'InputCheckbox',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: v4(),
    }
  },
  methods: {
    onValueChange() {
      if (this.disabled) return

      // eslint-disable-next-line
      this.$emit('update:modelValue', this.modelValue ? false : true)
    },
  },
}
</script>
