<template>
  <div class="input-password">
    <InputText
      :model-value="modelValue"
      type="password"
      :label="label"
      :placeholder="placeholder"
      :errors="validations.errors.modelValue"
      :show-errors="false"
      :inline="inline"
      :disabled="disabled"
      :small="small"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
    <i
      class="input-password__icon fas fa-info-circle"
      :class="{
        'input-password__icon--with-label': label,
      }"
      @click="showTooltip"
    ></i>
    <AppTooltip v-if="visibleTooltip" position="right">
      <div class="u-margin-bottom-xxsmall">
        <h5>Password must contain the following:</h5>
      </div>
      <div
        v-for="(item, index) in errorMessages"
        :key="index"
        class="input-password__tooltip-item"
      >
        <i
          class="fas fa-times input-password__icon--red"
          v-if="validations.errors.modelValue.includes(item)"
        ></i>
        <i class="fas fa-check input-password__icon--green" v-else></i>
        <span>{{ item }}</span>
      </div>
    </AppTooltip>
  </div>
</template>

<script>
import InputText from '@/core/components/inputs/InputText'
import {
  minLength,
  minSymbolCount,
  noDuplicates,
  required,
} from '@/core/plugins/validator/validators'
import AppTooltip from '@/core/components/content/AppTooltip'

const validators = {
  modelValue: [
    required(),
    minSymbolCount(1),
    noDuplicates(),
    minLength(12, null, 'This field needs to be at least 12 characters'),
  ],
}

const errorMessages = [
  'This field is required',
  'At least one special symbol',
  'No four sequential duplicate characters - case insensitive (i.e. aaaa, bbbb, cccc, aAaA, BbBb, CCcc)',
  'This field needs to be at least 12 characters',
]

export default {
  name: 'InputPassword',
  components: {
    AppTooltip,
    InputText,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Password',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localPassword: '',
      visibleTooltip: false,
      errorMessages: errorMessages,
    }
  },
  computed: {
    validations() {
      return this.$validator.validate(
        {
          modelValue: this.modelValue,
        },
        validators
      )
    },
  },
  methods: {
    showTooltip() {
      this.visibleTooltip = !this.visibleTooltip
    },
  },
  watch: {
    modelValue(newValue) {
      if (newValue && !this.validations.isValid) {
        this.visibleTooltip = true
      }
    },
  },
}
</script>
