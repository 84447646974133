<template>
  <div class="technology-selector">
    <div
      class="technology-selector__item"
      :class="{
        'technology-selector__item--selected':
          modelValue && modelValue.id === technology.id,
      }"
      v-for="technology in technologies"
      :key="technology.id"
      @click="onTechnologySelect(technology)"
    >
      <div class="technology-selector__heading" v-html="technology.name" />
      <div class="technology-selector__content" v-html="technology.content" />
      <AppTooltip v-if="technology.tooltip" position="top">
        <TechnologyTooltip :tooltip="technology.tooltip" />
      </AppTooltip>
    </div>
  </div>
</template>

<script>
import { TechnologyTypes } from '@/core/utils/constants'

import AppTooltip from '@/core/components/content/AppTooltip'
import TechnologyTooltip from '@/main/components/content/TechnologyTooltip'

export default {
  name: 'TechnologySelector',
  components: {
    AppTooltip,
    TechnologyTooltip,
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      technologies: [
        {
          id: TechnologyTypes.Printing,
          name: '3D Printing',
          content: 'SLA, SLS, FDM, Polyjet, MJF, DMLS',
          tooltip: [
            {
              title: 'SLA',
              name: 'Stereolithography',
              description:
                'High resolution polymer 3D printing process ideal for aesthetics and surface finish.',
            },
            {
              title: 'SLS',
              name: 'Selective Laser Sintering',
              description:
                'Medium resolution 3D printing process using production nylon plastics with the ability to scale to higher quantities and low-volume production',
            },
            {
              title: 'FDM',
              name: 'Fused Deposition Modeling',
              description:
                'Medium resolution 3D printing process using production thermoplastics ideal for medium-to-large parts with an emphasis on function over aesthetics',
            },
            {
              title: 'PolyJet',
              name: 'Polymer Jetting',
              description:
                'High resolution polymer 3D printing process ideal for aesthetics and accuracy with the ability to build in multiple materials and colors for visually-accurate prototypes',
            },
            {
              title: 'MJF',
              name: 'Multi Jet Fusion',
              description:
                'Medium resolution 3D printing process using production nylon plastics with the ability to scale to higher quantities and low-volume production',
            },
            {
              title: 'DMLS',
              name: 'Direct Metal Laser Sintering',
              description:
                'High resolution metal 3D printing process able to build complex designs non-manufacturable through machining or casting',
            },
          ],
        },
        {
          id: TechnologyTypes.CNCMachining,
          name: 'CNC Machining',
          content: '3-Axis, 5-Axis, Lathe, Plastics, Metals, Foams, Composites',
          tooltip:
            'Manufacturing process used to create highly precise and functional metal, plastic and composite parts',
        },
        {
          id: TechnologyTypes.UrethaneCasting,
          name: 'Urethane Casting',
          content: 'Prototype & Production, Rigid & Elastomeric',
          tooltip:
            'End-use and prototype manufacturing process using silicone molds to create low-volume production plastic parts with quantities ranging from 10-100.',
        },
        {
          id: TechnologyTypes.InjectionMolding,
          name: 'Injection Molding',
          content:
            'Rapid Tooling, Prototype, Production, Single and Multicavity',
          tooltip:
            'Traditional plastic manufacturing method for prototype, low-, medium- and high-volume production parts',
        },
        {
          id: TechnologyTypes.SheetMetal,
          name: 'Metal Cutting and Forming',
          content:
            'Laser & Waterjet Cutting, Wire EDM, Sheet Metal Stamping, Bending & Forming and Finishing',
          tooltip:
            'Sheet metal cutting, stamping, forming, and assembly.  Specializing in everything from low volume prototypes to high volume manufacturing and complex assemblies.',
        },
      ],
    }
  },
  methods: {
    onTechnologySelect(technology) {
      if (technology.link) {
        window.open(technology.link, '_blank').focus()
      } else {
        this.$emit('update:modelValue', technology)
      }
    },
  },
}
</script>
