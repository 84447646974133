import isEmail from 'validator/es/lib/isEmail'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import isURL from 'validator/es/lib/isURL'
import cardValidator from 'card-validator'
import isPostalCode from 'validator/es/lib/isPostalCode'
import matches from 'validator/es/lib/matches'

export const sameAs = (prop, label = 'Value', message) => {
  return {
    validator: (value, obj) => value === obj[prop],
    message: message ? message : `${label} must be same as ${prop}`,
  }
}

export const required = (label = 'This field', message) => {
  return {
    validator: value => {
      if (typeof value === 'string') {
        return value.length > 0
      }
      if (typeof value === 'number') {
        return true
      }
      if (typeof value === 'object') {
        return value instanceof Array ? value.length : !!value
      }
      return !!value
    },
    message: message ? message : `${label} is required`,
  }
}

export const minLength = (min, label = 'This field', message) => {
  return {
    validator: value =>
      typeof value === 'string' ? value.length >= min : false,
    message: message ? message : `${label} is less than ${min} characters`,
  }
}

export const email = (label = 'email', message) => {
  return {
    validator: value => isEmail(value),
    message: message ? message : `Invalid ${label}`,
  }
}

export const phone = (label = 'phone', message) => {
  return {
    validator: value => {
      if (!value.length) return true
      return isMobilePhone(value.replace(/[-+]/g, ''), 'en-US', {
        strictMode: false,
      })
    },
    message: message ? message : `Invalid ${label} number`,
  }
}

export const url = (label = 'This field', message) => {
  return {
    validator: value => {
      if (!value.length) return true
      return isURL(value)
    },
    message: message ? message : `${label} should be valid url`,
  }
}

export const isValidCreditCard = (label = 'This field', message) => {
  return {
    validator: value => {
      if (typeof value !== 'string') return true
      if (!value.length) return true
      return cardValidator.number(value.replace(/_/gi, '')).isValid
    },
    message: message ? message : `${label} should be valid credit card number`,
  }
}

export const isValidExpiryDate = (label = 'This field', message) => {
  return {
    validator: value => {
      if (typeof value !== 'string') return true
      const date = value.replace(/_/g, '')

      return cardValidator.expirationDate(date).isValid
    },
    message: message ? message : `${label} should be valid expiry date`,
  }
}

export const isValidCardCode = (label = 'This field', message) => {
  return {
    validator: value => {
      if (typeof value !== 'string') return true
      return (
        value.replace(/_/gi, '').length === 3 ||
        value.replace(/_/gi, '').length === 4
      )
    },
    message: message ? message : `${label} should be valid card code`,
  }
}

export const isZipcode = (label = 'zipcode', message) => {
  return {
    validator: value => isPostalCode(value, 'US'),
    message: message ? message : `Invalid ${label}`,
  }
}

export const isValidName = (label = 'This field', message) => {
  return {
    validator: value => matches(value, /^[a-zA-Z\s-]*$/),
    message: message ? message : `${label} should be valid name`,
  }
}

let upperCaseRegex = /^[A-Z]$/
let lowerCaseRegex = /^[a-z]$/
let numberRegex = /^[0-9]$/
let symbolRegex = /^[-@#!$%^&*()_+|~=`{}[\]:";'<>?,.\\/ ]$/

function countChars(str) {
  let result = {}
  Array.from(str).forEach(function(_char) {
    let curVal = result[_char]

    if (curVal) {
      result[_char] += 1
    } else {
      result[_char] = 1
    }
  })
  return result
}

function existDuplicateChars(str) {
  let result = false
  const re = /(?=(.))\1{4,}/g
  if (str) {
    const find = str.toLowerCase().match(re)
    result = !!find
  }
  return result
}

function analyzePassword(password) {
  let charMap = countChars(password)
  let analysis = {
    length: password.length,
    uniqueChars: Object.keys(charMap).length,
    uppercaseCount: 0,
    lowercaseCount: 0,
    numberCount: 0,
    symbolCount: 0,
    isDuplicateChars: existDuplicateChars(password),
  }
  Object.keys(charMap).forEach(function(_char2) {
    if (upperCaseRegex.test(_char2)) {
      analysis.uppercaseCount += charMap[_char2]
    } else if (lowerCaseRegex.test(_char2)) {
      analysis.lowercaseCount += charMap[_char2]
    } else if (numberRegex.test(_char2)) {
      analysis.numberCount += charMap[_char2]
    } else if (symbolRegex.test(_char2)) {
      analysis.symbolCount += charMap[_char2]
    }
  })
  return analysis
}

export const strongPassword = () => {
  return {
    validator: value => {
      const result = analyzePassword(value)
      return (
        !!result.uppercaseCount &&
        !!result.lowercaseCount &&
        !!result.numberCount &&
        !!result.symbolCount &&
        !result.isDuplicateChars
      )
    },
    message: 'Password does not meet security requirements',
  }
}

export const minSymbolCount = (min, label, message) => {
  return {
    validator: value => {
      const result = analyzePassword(value)
      return result.symbolCount >= min
    },
    message: message ? message : 'At least one special symbol',
  }
}

export const noDuplicates = (label, message) => {
  return {
    validator: value => {
      const result = analyzePassword(value)
      return value.length && !result.isDuplicateChars
    },
    message: message
      ? message
      : 'No four sequential duplicate characters - case insensitive (i.e. aaaa, bbbb, cccc, aAaA, BbBb, CCcc)',
  }
}
