<template>
  <div
    class="app-spinner"
    :class="{
      'app-spinner--small': small,
      'app-spinner--dark': dark,
      'app-spinner--in-place': inPlace,
    }"
  />
</template>

<script>
export default {
  name: 'AppSpinner',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    inPlace: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
