<template>
  <div class="maintenance-overlay">
    <div class="maintenance-overlay__body">
      <div class="container">
        <div class="maintenance-overlay__wrapper">
          <div class="maintenance-overlay__icon" />

          <div class="maintenance-overlay__content">
            <h1 class="maintenance-overlay__reason">
              Sorry, our site is under maintenance
            </h1>

            <p class="maintenance-overlay__description">
              It will be available soon. If you want to request a manual quote,
              please click
              <a
                class="link link--primary"
                href="https://fathommfg.com/manual-quote"
              >
                here
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaintenanceOverlay',
}
</script>
