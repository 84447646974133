<template>
  <div class="radio-group">
    <label v-if="label" class="radio-group__label radio-group__label--grey">
      {{ label }}
    </label>
    <div class="radio-group__items">
      <div
        class="radio-group__item"
        v-for="item in items"
        :key="item.value"
        :class="{ 'radio-group__item--selected': item.value === selected }"
        @click="selectItem(item.value)"
      >
        <span class="radio-group__item-icon" />
        <span class="radio-group__item-text" v-html="item.title"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputRadioGroup',
  props: {
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
      default: '',
    },
  },
  methods: {
    selectItem(item) {
      this.$emit('select', item)
    },
  },
}
</script>
