<template>
  <div class="app-topbar">
    <button class="app-topbar__btn" @click="toggleMenu" />
    <div class="app-topbar__logo">
      <AppLogo />
    </div>
    <div v-if="menuVisible" class="app-topbar__content">
      <PromoBox :mobile="true" @close="menuVisible = false">
        <template v-slot:menu>
          <div>
            <nav class="app-topbar__nav">
              <router-link :to="{ name: 'main' }" class="app-topbar__nav-item">
                Home
              </router-link>
              <a
                target="_blank"
                href="https://fathommfg.com/manual-quote"
                class="app-topbar__nav-item"
              >
                Manual quote
              </a>
              <a
                target="_blank"
                href="https://fathommfg.com/itar-request"
                class="app-topbar__nav-item"
              >
                ITAR
              </a>
              <router-link
                v-if="isLoggedIn"
                :to="{ name: 'dashboard' }"
                class="app-topbar__nav-item"
              >
                Dashboard
              </router-link>
              <template v-if="isLoggedIn">
                <a
                  href="#"
                  class="app-topbar__nav-item"
                  @click.prevent="showAccountSettings"
                >
                  Account Settings
                </a>
                <a
                  href="#"
                  class="app-topbar__nav-item"
                  @click.prevent="logout"
                >
                  Logout
                </a>
              </template>
            </nav>
            <button
              v-if="!isLoggedIn"
              class="app-topbar__sign-in"
              @click="showAuthModal"
            >
              Sign In
            </button>
            <a
              class="app-topbar__engineer-link"
              href="#"
              @click.prevent="showTalkEngineerModal"
            >
              <span class="app-topbar__engineer-icon" />
              Talk To An Engineer
            </a>
          </div>
        </template>
      </PromoBox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppLogo from '@/core/components/content/AppLogo'
import PromoBox from '@/core/components/content/PromoBox'

export default {
  name: 'AppTopbar',
  components: {
    AppLogo,
    PromoBox,
  },
  data() {
    return {
      menuVisible: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible
    },
    showAuthModal() {
      this.$vfm.show('auth')
      this.menuVisible = false
    },
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.replace({ path: '/' })
      this.menuVisible = false
    },
    showTalkEngineerModal() {
      this.$vfm.show('talk-to-engineer')
    },
    showAccountSettings() {
      this.$vfm.show('account-settings')
    },
  },
}
</script>
