<template>
  <div class="app-tooltip" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppTooltip',
  props: {
    position: {
      type: String,
      required: true,
      validator(value) {
        return ['top', 'bottom', 'left', 'right'].indexOf(value) !== -1
      },
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const result = [`app-tooltip--${this.position}`]

      if (this.fullWidth) {
        result.push('app-tooltip--full-width')
      }

      return result
    },
  },
}
</script>
