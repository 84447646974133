<template>
  <div
    class="input-text"
    :class="{
      'input-text--error': errors.length && showErrors,
      'input-text--inline': inline,
    }"
  >
    <label v-if="label" class="input-text__label">{{ label }}</label>
    <div class="input-text__wrapper">
      <textarea
        v-if="textarea"
        :value="modelValue"
        :placeholder="placeholder"
        :name="inputName"
        :style="styles"
        :disabled="disabled"
        class="input-text__elem input-text__elem--textarea"
        :class="{
          'input-text__elem--light': light,
          'input-text__elem--italic-placeholder': italicPlaceholder,
        }"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <input
        v-else
        :type="type"
        :value="modelValue"
        :placeholder="placeholder"
        :name="inputName"
        :style="styles"
        :disabled="disabled"
        class="input-text__elem"
        :class="{
          'input-text__elem--light': light,
          'input-text__elem--italic-placeholder': italicPlaceholder,
        }"
        v-maska="mask"
        @input="$emit('update:modelValue', $event.target.value)"
        @keypress.enter="$emit('enter')"
      />
      <i
        v-if="search"
        class="input-text__icon input-text__icon--light fas fa-search"
      ></i>
      <div v-if="errors.length && showErrors" class="input-text__errors">
        {{ errors[0] }}
      </div>
    </div>
  </div>
</template>

<script>
import { maska } from 'maska'

export default {
  name: 'InputText',
  directives: {
    maska,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    italicPlaceholder: {
      type: Boolean,
      default: false,
    },
    inputName: {
      type: String,
      required: false,
    },
    mask: {
      type: String,
      required: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    showErrors: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      return {
        width: this.width,
        height: this.height,
      }
    },
  },
}
</script>
