<template>
  <div class="app-wrapper">
    <router-view />

    <TalkToEngineerModal />
    <SuccessModal />
    <AuthModal />
    <AccountSettingsModal />
    <ForgotPasswordModal />
    <ResetPasswordModal />
    <CustomerInfoModal />
    <NonAcceptedFilesModal />
    <CheckActivityModal />
  </div>
</template>

<script>
import TalkToEngineerModal from '@/core/components/modals/TalkToEngineerModal'
import AuthModal from '@/auth/components/modals/AuthModal'

import AccountSettingsModal from '@/auth/components/modals/AccountSettingsModal'
import ForgotPasswordModal from '@/auth/components/modals/ForgotPasswordModal'
import ResetPasswordModal from '@/auth/components/modals/ResetPasswordModal'
import SuccessModal from '@/core/components/modals/SuccessModal'
import CustomerInfoModal from '@/core/components/modals/CustomerInfoModal'
import NonAcceptedFilesModal from '../modals/NonAcceptedFilesModal'
import CheckActivityModal from '@/auth/components/modals/CheckActivityModal'

export default {
  name: 'AppWrapper',
  components: {
    CheckActivityModal,
    NonAcceptedFilesModal,
    ResetPasswordModal,
    ForgotPasswordModal,
    AccountSettingsModal,
    TalkToEngineerModal,
    AuthModal,
    SuccessModal,
    CustomerInfoModal,
  },
}
</script>
