import { createRouter, createWebHistory } from 'vue-router'

import { getAccessToken } from '@/auth/helpers/storage'

import main from '@/main/routes'
import dashboard from '@/dashboard/routes'
import quote from '@/quote/routes'
import common from '@/common/routes'
import basic_upload from '@/basic_upload/routes'
import { LoginCallback } from '@okta/okta-vue'
import { navigationGuard } from '@okta/okta-vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...main,
    ...dashboard,
    ...quote,
    ...common,
    ...basic_upload,
    {
      path: '/login/callback',
      component: LoginCallback,
    },
  ],
})

router.beforeEach((to, from, next) => {
  const loggedIn = !!getAccessToken()

  if (to.matched.some(record => record.meta.requiresMainAuth) && !loggedIn) {
    return next({ name: 'main' })
  }

  next()
})

router.beforeEach(navigationGuard)

export default router
