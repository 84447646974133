<template>
  <button class="button-talk-engineer" @click="$emit('click')">
    <span class="button-talk-engineer__icon" />
    Talk To An Engineer
  </button>
</template>

<script>
export default {
  name: 'ButtonTalkEngineer',
  inheritAttrs: false,
}
</script>
