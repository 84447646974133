<template>
  <button
    class="button-primary"
    :class="{
      'button-primary--link': link,
      'button-primary--danger': danger,
      'button-primary--outline': outline,
      'button-primary--non-transform-text': nonTextTransform,
    }"
    :style="style"
    :disabled="disabled"
    :id="id"
    @click="$emit('click')"
  >
    <span
      v-if="icon"
      class="button-primary__icon"
      :class="`button-primary__icon--${icon}`"
    />
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonPrimary',
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    outline: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    nonTextTransform: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return {
        width: this.width,
      }
    },
  },
}
</script>
