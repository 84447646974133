<template>
  <div class="input-select" :class="classes" :style="selectStyles">
    <label v-if="label" class="input-select__label">{{ label }}</label>
    <div class="input-select__wrapper" v-click-outside="hide">
      <div
        class="input-select__input"
        :class="inputClasses"
        :style="inputStyles"
        @click="toggle"
      >
        <span v-if="selected" class="input-select__value">
          {{ selected }}
        </span>
        <span v-else-if="placeholder" class="input-select__placeholder">
          {{ placeholder }}
        </span>
        <i class="fas input-select__arrow fa-chevron-down" />
      </div>
      <div v-if="opened" class="input-select__list">
        <div
          v-for="option in options"
          :key="option.value"
          @click="selectOption(option.value)"
          class="input-select__option"
          :class="{
            'select__option--active': option.value === modelValue,
          }"
        >
          {{ option.title }}
        </div>
      </div>
      <div v-if="errors.length && showErrors" class="input-select__errors">
        {{ errors[0] }}
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from '@/core/directives/click-outside'

export default {
  name: 'InputSelect',
  directives: {
    ClickOutside,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    base: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    showErrors: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opened: false,
    }
  },
  computed: {
    selected() {
      const item = this.options.find(item => item.value == this.modelValue)

      if (item) {
        return item.title
      }
      return ''
    },
    classes() {
      return {
        'input-select--disabled': this.disabled,
        'input-select--inline': this.inline,
        'input-select--base': this.base,
      }
    },
    inputClasses() {
      return {
        'input-select__input--active': this.opened,
        'input-select__input--error': this.showErrors && this.errors.length,
        'input-select__input--center': this.center,
      }
    },
    selectStyles() {
      return {
        width: this.width,
      }
    },
    inputStyles() {
      return {
        height: this.height,
      }
    },
  },
  methods: {
    toggle() {
      if (this.disabled) return

      this.opened = !this.opened
    },
    hide() {
      if (this.disabled) return

      this.opened = false
    },
    selectOption(value) {
      if (this.disabled) return

      this.$emit('update:modelValue', value)
      this.opened = false
    },
  },
}
</script>
