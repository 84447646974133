export const acceptedFormats = params => {
  let result = ['stp', 'step', 'igs', 'iges', 'sldprt', '3dm', 'sat', 'x_t']

  if (params.is3D) {
    result = result.concat(['stl', 'obj'])
  }
  if (params.isSheetMetal) {
    //remove 3dm
    result.splice(5, 1)
    result = result.concat(['prt', 'dwg', 'dxf', 'pdf'])
  }

  return result
}
